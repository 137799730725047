.carousel-img {
    /* height: calc(100vh - 80px); */
    aspect-ratio: 16/5.2;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.carousel-slide {
    z-index: 1;
    height: calc(100vh - 80px);
}

.image-carousel {
    position: relative;
}

.carousel-img.clickable {
    cursor: pointer;
}