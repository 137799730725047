.blog-wrapper {
    width: 100%;
}

.blog {
    /* display: flex;
    flex-direction: row; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
}

.blog-img {
    flex: 1 1 auto;
    width: 100%;
    aspect-ratio: 16/12;
    object-fit: cover;
}

.blog-text-wrapper {
    align-self: center;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
}

.blog-title-link {
    text-decoration: none;
    color: black;
}

.blog-title-link * {
    text-decoration: underline;
    color: black;
}

.blog-text-title {
    flex: 1 1 0;
    margin-left: 0;
    color: black;
}

.blog-text {
    flex: 3 1 0;
}

#blog-title {
    align-self: center;
}

#add-blog-btn {
    border: 0;
    background-color: #fff;
    box-shadow: 2px 2px 5px 1px #888888;
    float: right;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 100;
}

#blog-title-wrapper {
    position: relative;
}

@media(max-width: 960px){
    .blog {
        display: flex;
        flex-direction: column;
    }
}