#blog-post-wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

#blog-post-wrapper * {
    margin-top: 10px
}

.blog-content-wrapper {
    margin: 0 100px 0 100px;
}

#blog-post-img {
    width: 100%;
    aspect-ratio: 16/12;
    object-fit: cover;
    margin-bottom: 20px;
}

.read-more {
    margin-top: 50px;
}

.read-more h2 {
    text-align: center;
}

.programmes-div {
    margin-top: 50px;
    margin-bottom: 50px;
}

.programmes-div h2 {
    text-align: center;
    margin-bottom: 30px;
}

.blog-title-wrapper {
    margin: 0 100px 0 100px;
}

.blog-title-wrapper * {
    text-align: center;
}

.next-blog {
    width: 75%;
    left: 0;
    right: 0;
    margin: auto;
}

@media(max-width: 960px){
    .blog-content-wrapper {
        margin: 0 10px 0 10px;
    }
    .next-blog {
        width: 95%;
    }
    .blog-text-wrapper {
        margin: 0;
    }
}