#testimonial-carousel {
    aspect-ratio: 16 / 3.5;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
}

.testimonial-wrapper {
    margin: auto
}

#testimonial-yellow {
    background-color: #fdbd41;
}

#testimonial-blue {
    background-color: var(--sheng-blue-colour);
}

#testimonial-green {
    background-color: #5ec4b7;
}

.testimonial-prev-next {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

#testimonial-carousel-prev {
    top: 50%;
    transform: translateY(-50%);
    left: 15%;
    font-size: 20px;
}

#testimonial-carousel-next {
    top: 50%;
    transform: translateY(-50%);
    right: 15%;
    font-size: 20px;
}

.testimonial-prev-next {
    color: white;
    position: absolute;
    opacity: 30%;
}

.testimonial-prev-next:hover {
    opacity: 70%;
}

#testimonial-carousel {
    width: 100%;
}

.testimonial-wrapper {
    width: 75%;
    height: 100%;
    display: block;
}

.testimonial-div {
    transition: transform 1s ease-in-out;
    display: flex;
    height:100%;
    width:75%;
    border-radius: calc(min(10vh,108px));
    justify-content: center;
    flex-direction: column;
    font-weight: 400;
    color: white;
    position: absolute;
    opacity: 100%;
    animation-direction: reverse;
}

.testimonial-current {
    transform: translateX(0);
}

.testimonial-left {
    transform: translateX(-105%);
}

.testimonial-right {
    transform: translateX(105%);
}

@keyframes wrap-right {
    0% {transform: translateX(-105%); opacity: 100%;}
    20% {transform: translateX(-125%); animation-timing-function: steps(1, end);}
    40% {opacity: 0; animation-timing-function: steps(1, end);}
    60% {transform: translateX(125%); opacity: 0;}
    80% {opacity: 100%}
    100% {transform: translateX(105%);}
}

@keyframes wrap-left {
    0% {transform: translateX(105%); opacity: 100%;}
    20% {transform: translateX(125%); animation-timing-function: steps(1, end);}
    40% {opacity: 0; animation-timing-function: steps(1, end);}
    60% {transform: translateX(-125%); opacity: 0;}
    80% {opacity: 100%}
    100% {transform: translateX(-105%);}
}

.testimonial-right.wrap {
    transition: none;
    animation: wrap-right 1s ease-in-out;
}

.testimonial-left.wrap {
    transition: none;
    animation: wrap-left 1s ease-in-out;
}

.testimonial-text {
    align-self: center;
    text-align: center;
    width: calc(80% - 15.625px);
}

.testimonial-customer {
    align-self: flex-end;
    margin-right: 10%;
    margin-top: 5%;
    max-width: 70%;
    text-wrap: wrap;
}

@media only screen and (max-device-width: 768px) {
    #testimonial-carousel {
        aspect-ratio: 1/1;
    }
    .testimonial-customer {
        /* margin-right: 0; */
    }
}