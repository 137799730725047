/* .footer-wrapper {
    color: white;
    background-color: var(--sheng-blue-colour);
    display: flex;
} */

footer {
    margin-top: 60px;
    background-color: var(--sheng-blue-colour);
}

.footer-wrapper {
    color: white;
    display: grid;
    gap: 10px;
    grid-template-columns: 2fr 1fr 1fr;
    width: 100%;
    max-width: 1920px;
    margin: auto;
}

#icon-col img {
    height: calc(8.5rem + 100px);
    top: 0;
    bottom: 0;
    margin: auto;
}

.footer-col-content-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.footer-col-content {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.footer-col-content-col {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.footer-col-content-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.footer-link {
    color: white;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-icon {
    color: white;
}

@media(max-width: 960px){
    .footer-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-col-content-wrapper {
        align-items: center;
        justify-content: center;
    }
    .footer-col-content-col {
        text-align: center;
    }
    #icon-col img {
        width: 100%;
        height: auto;
        float: left;
    }
    #icon-col {
        order: 3;
    }
}