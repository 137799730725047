@property --sheng-blue-colour {
  syntax: "<color>";
  inherits: false;
  initial-value: #405ca4;
}

body {
  background-color: #ffffff;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  white-space: pre-wrap;
}

p:empty:before {
  content: ' ';
  white-space: pre;
}

*{
  margin: 0;
}

.blue-underline{
  text-decoration: underline;
  text-decoration-thickness: 0.1em;
  text-decoration-color: var(--sheng-blue-colour);
  text-underline-offset: 0.4em;
}

.underline{
  text-decoration: underline;
  text-underline-offset: 0.1em;
  text-decoration-color: black;
}

.main{
  max-width: 1920px;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}

.center-content{
  text-align: left;
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 75%;
}

.row-wrapper{
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  /* background:rgb(253, 253, 253); */
  border-radius:4px;
  padding: 10px;
}

.col-element {
  flex: 1;
  margin: 10px;
}

#banner {
  position: relative;
  aspect-ratio: 12/4;
  width: 100%;
}

#banner-img {
  width: 100%;
  height: auto;
}

#banner-title {
  color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 53%;
  font-size: min( 5vw, 96px );
} 

#whatsapp-contact {
  position: fixed;
  bottom: 20px;
  right: 20px;
  margin: auto;
  z-index: 2000;
  height: 75px;
  cursor: pointer;
}

@media(max-width: 960px){
  .center-content{
    width: 95%;
  }
  body{
    font-size: 14px;
  }
}