.row-wrapper {
    align-items: center;
    overflow: hidden;
}

.link-wrapper {
    cursor: pointer;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    display: flex;
    flex-direction: row;
}

.link-text-content {
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: column;
}

.contact-link {
    text-align: start;
    word-break: break-word;
    align-items: center;
    gap: 10px;
}

.contact-us-icons {
    height: 6em;
}

.info-img {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: cover;
}

.info-window {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    gap: 10px;
}

#map {
    height: 1000px;
}

@media(max-width: 960px){
    .contact-row{
        align-items: start;
    }
    #map {
        height: 80vh;
    }
    .row-title {
        text-align: center;
    }
}