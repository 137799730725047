.enrichment_class {
    align-items: center;
    position: relative;
}

.enrichment_class img {
    width: 100%;
}

.enrich-learn-more-div {
    overflow: hidden;
    max-height: 100vh;
    transition-property: max-height;
    transition-duration: 1s;
}

.learn-more-hidden .enrich-learn-more-div {
    transition: max-height 1s cubic-bezier(0, 1, 0, 1);
    max-height: 0;
}

#contact-us-btn {
    background-color: #fcbe51;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
}

.new-enrichment-overlay-background {
    display: block;
    position: fixed;
    width:100%; 
    height:100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index:1001;
    top:0; 
    left:0;
}

.new-enrichment-overlay.hidden div{
    display: none;
}

.new-enrichment-wrapper {
    margin: auto;
    position: fixed;
    overflow: hidden;
    margin-bottom: 30px;    
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
}

.new-enrichment-save-btn{
    position: fixed;
    z-index: 1002;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.edit-enrichment-div {
    background-color: #fff;
}

.edit-enrichment-banner-div {
    position: relative;
}

.edit-enrichment-banner-div img{
    max-height: 42vh;
}

#enrichment-add-btn {
    border: none;
    background-color: #fff;
    box-shadow: 2px 2px 5px 1px #888888;
    float: right;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 100;
}

#enrichment-add-btn-wrapper {
    position: relative;
}