.dropdown-icon {
  color: var(--sheng-blue-colour);
}

.navbar {
  background: white;
  padding: 0;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 1500px;
}

.navbar-logo {
  height: 100%;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
}

.logo-img {
  height: 100%;
}

.dropbtn {
  align-self: center;
  cursor: pointer;
  font-size: 1.2rem; 
  border: none;
  outline: none;
  color: black;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 75px;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 99999;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
  z-index: 99999;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  margin: 0;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--sheng-blue-colour);
  text-underline-offset: 5px;
}

.fa-bars {
  color: var(--sheng-blue-colour);
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    gap: 0;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 80px);
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: start;
  }

  .nav-menu.active {
    padding: 0;
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 0.5rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    text-decoration: none;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
  }

  .menu-icon {
    color: black;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 45%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }

  .nav-item {
    height: auto;
    z-index: 1;
  }

  .nav-item::after {
    width: 50%;
    border-bottom: #242424;
    border-style: outset;
    border-width: 1px;
  }

  .dropbtn {
    padding: 0.5rem;
  }

  .dropdown.nav-links {
    padding: 0;
  }

  .dropdown.active .dropdown-content {
    max-height: 100vh;
    transition: max-height 0.5s ease-in-out;
  }

  .dropdown.active button i {
    transform: scaleY(-1);
  }

  .dropdown-content {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    background-color: white;
    min-width: 160px;
    box-shadow: none;
    z-index: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }

  .dropdown-content a {
    background-color: #f7f7f7;
    float: none;
    color: black;
    padding: 0.5rem;
    text-decoration: none;
    display: block;
  }
}

@media (min-width : 961px) {
  .dropdown:hover .dropdown-content {
    display: flex;
    flex-direction: column;
  }

  .dropdown-links:hover {
    background-color: #f7f7f7;
  }
}