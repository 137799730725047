.breadcrumbs {
    display:inline-block;
}

.breadcrumbs * {
    margin-right: 3px;
}

.breadcrumbs-links {
    text-decoration: none;
    color: black;
}

.breadcrumbs-arrow::before {
    vertical-align: middle;
}