.brochure-wrapper {
    background-color: #5ec4b7;
    border-radius: calc(min(15vh,162px));
}

.brochure-title {
    color: #405caa;
    text-align: center;
    margin-top: 50px;
}

.brochure-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5%;
    margin: 2% 5% 2% 5%;
}

.brochure-icon img {
    width: 100%;
}

.brochure-text{
    color: white;
    display: flex;
    align-items: center;
}

.blue-text {
    color: #405caa;
}

#student-care-video {
    aspect-ratio: 112/63;
    width: 100%;
}

#student-care-infographic {
    width: 100%;
}
