.Main{
  text-align: center;
}

.col-element {
  flex: 1;
  margin: 10px;
}

.link-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-height: 0;
  min-width: 0;
  gap: 25px;
  width: 100%;
}

.grid-link {
  overflow: hidden;
}

.grid-link img {
  width: 100%;
}

.rounded-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rounded-img-wrapper img{
  width: 100%;
  object-fit: cover;
  height: 25rem;
}

.row-title {
  position: relative;
}

/* #testimonial-row {
  width: 75vw;
  height: 40vh;
}

#testimonial-carousel {
  height: 30vh;
}

.testimonial-wrapper {
  width: 75vw;
  height: 30vh;
  display: block;
}

.testimonial-div {
  height:100%;
  width:100%;
  background-color: var(--sheng-blue-colour);
  border-radius: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  color: white;
}

.testimonial-text {
  align-self: center;
  text-align: center;
  width: calc(100% - 150px);
}

.testimonial-customer {
  align-self: flex-end;
  margin-right: 10vw;
  margin-top: 5em;
} */

.carousel-control-prev {
  color: black;
}

#consult-btn {
  background-color: #fcbe51;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  float: right;
}

@media(max-width: 960px){
  .rounded-img-wrapper img{
    height: 100%;
  }

  .row-wrapper{ 
      width: calc(100% - 5px);
      flex-direction: column;
      align-items: center;
  }

  .rounded-img-wrapper img{
    width: calc(100% - 10px);
    object-fit: contain;
  }

  .profile-right {
      height: inherit;
      margin-left: inherit;
      margin-top: 10px;
      text-align: center;
  }

  .profile-box-content{
      text-align:center;
  }

  .link-grid-container {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}