:root {
    --YELLOW-elem-height: 0px;
    --BLUE-elem-height: 0px;
    --GREEN-elem-height: 0px;
    --GRAY-elem-height: 0px;
}

.subjects {
    color: white;
    border-radius: 150px;
    height: 100px;
    min-width: 14rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--subj-color);
}

.YELLOW {
    --subj-color: #fcbe51;
    --multiplier : 0;
}

.BLUE {
    --subj-color: #3e5ca6;
    --multiplier : 1;
}

.GREEN {
    --subj-color: #67c3b8;
    --multiplier : 2;
}

.GRAY {
    --subj-color: #5a5a5a;
    --multiplier : 3;
}

.subject-content {
    margin: 0 30px 0 30px;
}

.subject-row.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
}

.subject-row-right {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.subject-tutors-imgs {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.subject-tutors-imgs img {
    aspect-ratio: 1/1;
    height: 5em;
    object-fit: cover;
    border-radius: 50%;
}

.subject-row-image img {
    aspect-ratio: 16/11;
    object-fit: cover;
    width: 100%;
    border-radius: calc(min(10vh,108px));
}

.subject-title {
    margin-top: 50px;
    flex-grow: 2;
}

.subject-tutors {
    flex-grow: 1;
}

/* .subject-row.learn-more {
    align-items: center;
} */

.learn-more-btn {
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    padding: 0;
    border: none;
    background: none;
}

.learn-more-btn i {
    color: #d9d9d9;
    font-size: 25px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.learn-more-btn p {
    margin: 0 5px 0 5px;
}

.learn-more-div {
    overflow: hidden;
    max-height: 200vh;
    transition-property: max-height;
    transition-duration: 1.5s;
}

.learn-more-hidden .learn-more-div {
    max-height: 0;
    transition: max-height 1s cubic-bezier(0, 1, 0, 1);
}

.learn-more-hidden .fa-caret-up {
    transform: scaleY(-1);
}

#subject-stack-wrapper {
    margin-top: 100px;
    position: relative;
    width: 100%;
    margin-bottom: 100px;
}

.subject-contents-wrapper {
    position: relative;
    background-color: #ffff;
    padding: 0;
    padding-bottom: 75px;
    border: 15px solid var(--subj-color);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.subject-contents-wrapper.BLUE {
    margin-top: calc(0px - var(--YELLOW-elem-height) + 100px);
}

.subject-contents-wrapper.GREEN {
    margin-top: calc(0px - var(--BLUE-elem-height) + 100px);
}

.subject-contents-wrapper.GRAY {
    margin-top: calc(0px - var(--GREEN-elem-height) + 100px);
}

.subject-contents-wrapper.opened {
    margin-top: -75px;
    transition-property: margin-top;
    transition-duration: 1s;
}

.subject-content-subject {
    width: 20vw;
    background-color: var(--subj-color);
    margin-top: -15px;
    justify-self: center;
}

/* .subject-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
} */

.subject-content-image > img{
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.subject-content-right {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.subject-learn-more {
    background-color: var(--subj-color);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    width: 30%;
    margin-left: auto;
    order: 2;
    margin-right: 5%;
}

@media(max-width: 960px){

    .subject-row.grid {
        gap: 0;
        display: flex;
        flex-direction: column;
    }

    #subject-stack-wrapper {
        margin-top: 35px;
    }

    .subject-title {
        margin: 0;
    }

}