.tutors-div {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.tutor-div {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
}

.tutor-img img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
}

.tutor-info {
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.tutor-info .tutor-title {
    text-align: center;
}

.text-title {
    align-self: center;
}

@media(max-width: 960px){
    .tutor-div {
        display: flex;
        flex-direction: column;
    }
}